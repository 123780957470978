<template>
    <div>
        <v-date-picker
            class="mb-2"
            v-model="fechas"
            range
            :max="estaFecha"
            full-width
            no-title
            locale="es-mx"
            scrollable
            show-adjacent-months
            :disabled="deshabilitado"
        />

    </div>
</template>

<script>
export default {
    props:{
        fechasTodas:Array,
        deshabilitado:Boolean,
        futuro: Boolean,
    },
    data:()=>({
        fechas:[],
        fechaArr:[],
    }),
    computed:{
        estaFecha(){
            let ef = this.moment(new Date()).format('YYYY-MM-DD')
            if (this.futuro==true){
                ef=''
            }

            return ef
        },
        fechaDe(){
            return (this.getFecha(this.fechaArr[0]))
        },
        fechaA(){
            return (this.getFecha(this.fechaArr[1]))
        },

        //fechaArr:{
        //    get(){return this.fechasTodas},
        //    set(val){this.$emit('update:fechasTodas', val)}
        //},

    },
    watch:{
        fechas(){
            let far=[]
            this.fechaArr = []
            this.$emit('update:fechasTodas',this.fechaArr)
            if(this.fechas.length > 0){
                this.fechas.forEach(fecha => {
                    far.push(new Date(fecha+'T00:00:00').getTime())
                })
                this.fechaArr.push(new Date(Math.min.apply(null,far)).getTime())
                this.fechaArr.push(new Date(Math.max.apply(null,far)).getTime())
                this.fechaArr[0]=this.moment(this.fechaArr[0]).format('YYYY-MM-DD')
                this.fechaArr[1]=this.moment(this.fechaArr[1]).format('YYYY-MM-DD')
            }
            this.$emit('update:fechasTodas',this.fechaArr)
            //console.log(this.fechaArr)
        },
    },

}
</script>

<style>

</style>